import React, { useState, useEffect } from 'react';
import { Calendar, Clock, MapPin, Edit } from 'lucide-react';

const MainView = () => {
    const [selectedCompetition, setSelectedCompetition] = useState('');
    const [selectedTeam, setSelectedTeam] = useState('');
    const [availableTeams, setAvailableTeams] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const [standings, setStandings] = useState([]);
    const [results, setResults] = useState([]);
    const [program, setProgram] = useState([]);
    const [nextMatch, setNextMatch] = useState(null);
    const [lastResult, setLastResult] = useState(null);
    const [isEditingPreferences, setIsEditingPreferences] = useState(true);

    const handleSavePreferences = () => {
        localStorage.setItem('userCompetition', selectedCompetition);
        localStorage.setItem('userTeam', selectedTeam);
        setIsEditingPreferences(false);
    };

    const handleEditPreferences = () => {
        setIsEditingPreferences(true);
    };


    useEffect(() => {
        const savedCompetition = localStorage.getItem('userCompetition');
        const savedTeam = localStorage.getItem('userTeam');

        if (savedCompetition && savedTeam) {
            setSelectedCompetition(savedCompetition);
            setSelectedTeam(savedTeam);
            setIsEditingPreferences(false);
        } else {
            setIsEditingPreferences(true);
        }

        fetchCompetitions();
    }, []);

    useEffect(() => {
        if (selectedCompetition) {
            fetchTeams(selectedCompetition);
            fetchStandings(selectedCompetition);
            fetchResults(selectedCompetition);
            fetchProgram(selectedCompetition);
        }
    }, [selectedCompetition]);

    useEffect(() => {
        if (selectedTeam) {
            updateNextMatchAndLastResult();
        }
    }, [selectedTeam, program, results]);

    const fetchCompetitions = async () => {
        try {
            const response = await fetch('/api/competitions');
            const data = await response.json();
            setCompetitions(data);
        } catch (error) {
            console.error('Error fetching competitions:', error);
        }
    };

    const fetchTeams = async (pouleId) => {
        try {
            const response = await fetch(`/api/teams/${pouleId}`);
            const data = await response.json();
            setAvailableTeams(data);
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const fetchStandings = async (pouleId) => {
        try {
            const response = await fetch(`/api/standings/${pouleId}`);
            const data = await response.json();
            setStandings(data);
        } catch (error) {
            console.error('Error fetching standings:', error);
        }
    };

    const fetchResults = async (pouleId) => {
        try {
            const response = await fetch(`/api/results/${pouleId}`);
            const data = await response.json();
            setResults(data);
        } catch (error) {
            console.error('Error fetching results:', error);
        }
    };

    const fetchProgram = async (pouleId) => {
        try {
            const response = await fetch(`/api/program/${pouleId}`);
            const data = await response.json();
            setProgram(data);
        } catch (error) {
            console.error('Error fetching program:', error);
        }
    };

    const updateNextMatchAndLastResult = () => {
        if (selectedTeam) {
            const teamNextMatch = program.find(match =>
                match.team1 === selectedTeam || match.team2 === selectedTeam
            );
            setNextMatch(teamNextMatch || null);

            const teamLastResult = results.find(result =>
                result.team1 === selectedTeam || result.team2 === selectedTeam
            );
            setLastResult(teamLastResult || null);
        } else {
            setNextMatch(null);
            setLastResult(null);
        }
    };

    const PreferencesSection = () => (
        <div className="bg-white rounded-lg shadow-md p-4 mb-4">
            {isEditingPreferences ? (
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="competition" className="block mb-2">Competition:</label>
                            <select
                                id="competition"
                                value={selectedCompetition}
                                onChange={(e) => setSelectedCompetition(e.target.value)}
                                className="w-full p-2 border rounded"
                            >
                                <option value="">Select a competition</option>
                                {competitions.map((comp) => (
                                    <option key={comp.pouleId} value={comp.pouleId}>{comp.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="team" className="block mb-2">Team:</label>
                            <select
                                id="team"
                                value={selectedTeam}
                                onChange={(e) => setSelectedTeam(e.target.value)}
                                className="w-full p-2 border rounded"
                                disabled={!selectedCompetition}
                            >
                                <option value="">Select a team</option>
                                {availableTeams.map((team) => (
                                    <option key={team.name} value={team.name}>{team.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <button
                        onClick={handleSavePreferences}
                        className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 transition-colors"
                    >
                        Save Preferences
                    </button>
                </div>
            ) : (
                <div className="flex justify-between items-center">
                    <div>
                        <p><strong>Competition:</strong> {competitions.find(c => c.pouleId === selectedCompetition)?.name || 'Not selected'}</p>
                        <p><strong>Team:</strong> {selectedTeam || 'Not selected'}</p>
                    </div>
                    <button
                        onClick={handleEditPreferences}
                        className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300 transition-colors flex items-center"
                    >
                        <Edit className="w-4 h-4 mr-2" />
                        Edit
                    </button>
                </div>
            )}
        </div>
    );

    const formatShortDate = (dateString) => {
        const [day, monthName] = dateString.split(' ');
        const dutchMonths = [
            'januari', 'februari', 'maart', 'april', 'mei', 'juni',
            'juli', 'augustus', 'september', 'oktober', 'november', 'december'
        ];
        const monthIndex = dutchMonths.findIndex(m => m.toLowerCase() === monthName.toLowerCase());
        const month = (monthIndex + 1).toString().padStart(2, '0');
        return `${day.padStart(2, '0')}/${month}`;
    };

    const formatShortTime = (timeString) => {
        return timeString.split('-')[0].trim();
    };

    const NextMatch = ({ match }) => (
        <div className="bg-white rounded-lg shadow-md p-4 mb-4">
            <h2 className="text-xl font-bold mb-2">Next Match</h2>
            {match ? (
                <div className="flex justify-between items-center">
                    <div>
                        <div className="flex items-center text-gray-600 mb-1">
                            <Calendar className="w-4 h-4 mr-2" />
                            {match.date}
                        </div>
                        <div className="flex items-center text-gray-600 mb-1">
                            <Clock className="w-4 h-4 mr-2" />
                            {match.time}
                        </div>
                        <div className="flex items-center text-gray-600">
                            <MapPin className="w-4 h-4 mr-2" />
                            {match.location}
                        </div>
                    </div>
                    <div className="text-right">
                        <div className="font-semibold">{match.team1}</div>
                        <div className="font-semibold">{match.team2}</div>
                    </div>
                </div>
            ) : (
                <p>No upcoming matches scheduled for your team.</p>
            )}
        </div>
    );

    const LastResult = ({ result }) => (
        <div className="bg-white rounded-lg shadow-md p-4 mb-4">
            <h2 className="text-xl font-bold mb-2">Last Result</h2>
            {result ? (
                <div className="flex flex-col">
                    <div className="flex justify-between items-start mb-2">
                            <div className="flex items-center text-gray-600">
                                <Calendar className="w-4 h-4 mr-2" />
                                {formatShortDate(result.date)}
                            </div>
                    </div>
                    <div className="grid grid-cols-[1fr_auto_1fr] items-center">
                        <div className="font-semibold text-left">{result.team1}</div>
                        <div className="text-xl font-bold text-orange-500 mx-4">
                            {result.score}
                        </div>
                        <div className="font-semibold text-right">{result.team2}</div>
                    </div>
                </div>
            ) : (
                <p>No recent results available for your team.</p>
            )}
        </div>
    );

    const Results = ({results}) => {
        const [showOnlyUserTeamResults, setShowOnlyUserTeamResults] = useState(true);

        const getWinnerStyle = (score, isTeam1) => {
            if (!score) return '';
            const [score1, score2] = score.split('-').map(Number);
            if (score1 === score2) return '';
            if ((isTeam1 && score1 > score2) || (!isTeam1 && score2 > score1)) {
                return 'font-bold';
            }
            return '';
        };

        return (
            <div className="bg-white rounded-lg shadow-md p-4 mb-4">
                <h2 className="text-xl font-bold mb-2">Results</h2>
                <div className="flex items-center mb-2">
                    <input
                        type="checkbox"
                        id="showOnlyUserTeamResults"
                        checked={showOnlyUserTeamResults}
                        onChange={(e) => setShowOnlyUserTeamResults(e.target.checked)}
                        className="mr-2"
                    />
                    <label htmlFor="showOnlyUserTeamResults">Show only my team</label>
                </div>
                <div className="overflow-hidden rounded-lg">
                    {results
                        .filter(result => !showOnlyUserTeamResults || result.team1 === selectedTeam || result.team2 === selectedTeam)
                        .map((result, index) => (
                            <div key={index} className={`grid grid-cols-[1fr_auto_1fr] gap-4 items-center p-2 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                <div className={`text-left ${result.team1 === selectedTeam ? 'text-orange-500' : ''} ${getWinnerStyle(result.score, true)}`}>
                                    {result.team1}
                                </div>
                                <div className="font-bold text-center">
                                    {result.score}
                                </div>
                                <div className={`text-right ${result.team2 === selectedTeam ? 'text-orange-500' : ''} ${getWinnerStyle(result.score, false)}`}>
                                    {result.team2}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        );
    };

    const Program = ({ program }) => {
        const [showOnlyUserTeamProgram, setShowOnlyUserTeamProgram] = useState(true);

        return (
            <div className="bg-white rounded-lg shadow-md p-4 mb-4">
                <h2 className="text-xl font-bold mb-2">Program</h2>
                <div className="flex items-center mb-2">
                    <input
                        type="checkbox"
                        id="showOnlyUserTeamProgram"
                        checked={showOnlyUserTeamProgram}
                        onChange={(e) => setShowOnlyUserTeamProgram(e.target.checked)}
                        className="mr-2"
                    />
                    <label htmlFor="showOnlyUserTeamProgram">Show only my team</label>
                </div>
                {program
                    .filter(match => !showOnlyUserTeamProgram || match.team1 === selectedTeam || match.team2 === selectedTeam)
                    .map((match, index) => (
                        <div key={index} className={`p-2 ${index % 2 === 0 ? 'bg-gray-100' : ''}`}>
                            <div className="grid grid-cols-[auto_1fr] gap-4 items-center">
                                <div className="flex flex-col items-start">
                                    <div className="flex items-center">
                                        <Calendar className="w-4 h-4 mr-1"/>
                                        <span>{formatShortDate(match.date)}</span>
                                    </div>
                                    <div className="flex items-center mt-1">
                                        <Clock className="w-4 h-4 mr-1"/>
                                        <span>{formatShortTime(match.time)}</span>
                                    </div>
                                    <div className="flex items-center mt-1">
                                        <MapPin className="w-4 h-4 mr-1"/>
                                        <span className="text-sm text-gray-600">{match.location}</span>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <div className={match.team1 === selectedTeam ? 'font-bold text-orange-500' : ''}>
                                        {match.team1}
                                    </div>
                                    <div className={match.team2 === selectedTeam ? 'font-bold text-orange-500' : ''}>
                                        {match.team2}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        );
    };
    const Standings = ({standings}) => {
        const [showDetailed, setShowDetailed] = useState(false);

        const columnNames = {
            p: "Played",
            w: "Won",
            d: "Draw",
            l: "Lost",
            pts: "Points",
            gf: "Goals For",
            ga: "Goals Against"
        };

        return (
            <div className="bg-white rounded-lg shadow-md p-4 mb-4">
                <h2 className="text-xl font-bold mb-2">Standings</h2>
                <div className="flex items-center mb-2">
                    <input
                        type="checkbox"
                        id="showDetailedStandings"
                        checked={showDetailed}
                        onChange={(e) => setShowDetailed(e.target.checked)}
                        className="mr-2"
                    />
                    <label htmlFor="showDetailedStandings">Show detailed view</label>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead>
                        <tr className="bg-gray-100">
                            <th className="p-2 text-left w-5">#</th>
                            <th className="p-2 text-left">Team</th>
                            <th className="p-2 text-center w-12" title={columnNames.p}>P</th>
                            {showDetailed && (
                                <>
                                    <th className="p-2 text-center w-10" title={columnNames.w}>W</th>
                                    <th className="p-2 text-center w-10" title={columnNames.d}>D</th>
                                    <th className="p-2 text-center w-10" title={columnNames.l}>L</th>
                                    <th className="p-2 text-center w-10" title={columnNames.gf}>GF</th>
                                    <th className="p-2 text-center w-10" title={columnNames.ga}>GA</th>
                                </>
                            )}
                            <th className="p-2 text-center w-16" title={columnNames.pts}>Pts</th>
                        </tr>
                        </thead>
                        <tbody>
                        {standings.map((team) => (
                            <tr key={team.position} className={team.team === selectedTeam ? 'bg-orange-100' : ''}>
                                <td className="p-2 text-left w-5">{team.position}</td>
                                <td className="p-2 font-semibold">{team.team}</td>
                                <td className="p-2 text-center">{team.played}</td>
                                {showDetailed && (
                                    <>
                                        <td className="p-2 text-center">{team.won}</td>
                                        <td className="p-2 text-center">{team.draw}</td>
                                        <td className="p-2 text-center">{team.lost}</td>
                                        <td className="p-2 text-center">{team.goalsFor}</td>
                                        <td className="p-2 text-center">{team.goalsAgainst}</td>
                                    </>
                                )}
                                <td className="p-2 text-center font-bold">{team.points}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };
    return (
        <div className="container mx-auto px-4 py-8 bg-gray-50">
            <PreferencesSection />
            <NextMatch match={nextMatch} />
            <LastResult result={lastResult} />
            <Standings standings={standings} />
            <Program program={program} />
            <Results results={results} />
        </div>
    );
};

export default MainView;